export const italianStrings = {
	login: {
		header: "Accedi alla Community",
		var_header: "Accedi",
		description: "Bentornato/a alla Community",
		var_description: "Bentornato/a",
		loginForm: {
			email: "Indirizzo email",
			password: "Password",
			terms_link: "Termini e condizioni d'uso",
			terms_text:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
			button: "Accedi",
			already_member: "Sei già iscritto?",
			register: "Registrati",
			forgotPassword: "Password dimenticata?",
		},
	},
	update_profile: {
		save_changes: "Salva",
	},
	register: {
		title: "Registrati alla Community",
		description: "Unisciti oggi alla Community",
		privacy_policy: "Privacy Policy",
		complete_registration: "Registrazione completata!",
		account_activation_note:
			"Ancora un ultimo passo, dovresti aver ricevuto una mail di conferma del tuo account, segui le istruzioni per finalizzare la tua iscrizione",
		account_activation_note2: "Se non la trovi cerca nella casella SPAM!",
		go_login: "Accedi",
		registerForm: {
			title: "Titolo",
			first_name: "Name",
			last_name: "Cognome",
			email: "Email",
			email_confirmation: "Ripetere email",
			password: "Password",
			password_confirmation: "Ripetere password",
			profession: "Professione",
			territory: "Ospedale/Territorio",
			specialization: "Disciplina",
			specialization_2: "Disciplina 2",
			employment: "Operatività",
			province_enployment: "Provincia attività lavorativa",
			regione_enployment: "Regione attività lavorativa",
			board_member: "Ordine professionale",
			province_board: "Provincia di all ordine",
			board_number: "Numero iscrizione Ordine",
			next: "Avanti",
			back: "Indietro",
			password_not_matched: "La password non corrisponde a quella inserita",
			email_not_matched: "L'indirizzo email non corrisponde a quella inserita",
			email_used: "Indirizzo email già in uso",
			phone_number: "Telefono",
			bio: "Bio",
			bio_placeholder: "Descrizione (massimo 300 caratteri)",
			social_links: "Social",
			already_have_account: "Sono già iscritto",
			submit_register: "Completa la Registrazione",
		},
	},
	forgot_password: {
		header: "Recupera la password",
		description:
			"Inserisci l'e-mail associata al tuo account e ti invieremo un'e-mail con le istruzioni per reimpostare la password.",
		form: {
			email: "Indirizzo Email",
			button: "Invia le istruzioni",
			back: "Indietro",
		},
	},

	change_password: {
		header: "Crea una nuova password",
		description:
			"La nuova password deve essere diversa dalle password utilizzate in precedenza.",
		password: "Password",
		password_confirmation: "Conferma la password",
		old_password: "Vecchia password",
		button: "Reset Password",
		password_required: "Inserisci la password",
		new_password_length: "La nuova password deve contenere almeno 8 caratteri.",
		passwords_missmatch:
			"La nuova password deve essere diversa dalle password utilizzate in precedenza.",
	},

	gikis: {
		menu: {
			title: "Dashboard",
			suggest: "Consiglia una nuova Community",
		},
		newInvites: "Nuovi inviti",
		recommendations: "Le nuove Community",
		findGikis: "Trova la tua Community",
		allGikis: "Tutte le Community",
		members: {
			no_bio: "Nessuna Bio disponibile",
			Member_since: "Iscritto da",
			Users: "Utenti",
		},
		gikis_in_common_with_you: "Community in comune",
	},
	"Account has not activated": "L'account non è stato attivato",
	"Account activated successfully": "Account attivato con successo",
	"Check your email": "Controlla la tua posta elettronica",
	"We have sent a password recover instructions to your email.":
		"Abbiamo inviato le istruzioni per il recupero della password alla tua email.",
	"Go to Login": "Accedi",
	"Skip, I’ll confirm later": "Salta, confermerò più tardi",
	"Password reset successfully": "Reimpostazione della password riuscita",
	"Something goes wrong": "Qualcosa è andato storto",
	About: "About",
	Privacy: "Privacy",
	Terms: "Terms",
	FAQ: "FAQ",
	"Terms of use": "Termini e condizioni d’uso",
	"Privacy Policy Consent": "Privacy Policy",
	"Please accept website privacy policy to continue":
		"Accetta l'informativa sulla privacy del sito web per continuare",
	"MAKE CONSENT": "Fornisci il consenso",
	Notifications: "Notifiche",
	"Mark all as read": "Segna tutto come letto",
	Search: "Cerca",
	"All Gikis": "Tutte le Community",
	Members: "Iscritti",
	Messages: "Messaggi",
	Media: "Media",
	"My Gikis": "La mia Community",
	Contacts: "Contatti",
	ADMIN: "ADMIN",
	"Select a member from the list to view their profile":
		"Seleziona un membro dall'elenco per visualizzare il suo profilo",
	Dashboard: "Dashboard",
	"Invited you to join": "Ti ho invitato a iscriverti",
	Accept: "Accetta",
	Decline: "Rifiuta",
	"You do not have any pending requests!": "Non hai richieste in sospeso!",
	"There are no": "Non hai richieste in sospeso!",
	New: "New",
	"You have not yet joined any Giki yet! Start exploring Giki":
		"Non ti sei ancora iscritto a nessuna Community! Inizia ad esplorare la Community",
	here: "Qui",
	"Select a Giki from the list to continue interacting":
		"Seleziona una Community dall'elenco per continuare a interagire",
	"You have not yet joined any Giki yet!":
		"Non ti sei ancora iscritto a nessuna Community!",
	"Start exploring Gikis!": "Inizia ad esplorare la Community!",
	online: "online",
	Info: "Info",
	Pinned: "Messaggi fissati",
	Mute: "Muta",
	"Created on": "Creato il",
	Success: "Modifiche completate",
	"Your changes has been applied": "Le tue modifiche sono state applicate",
	"Edit Giki Profile Details": "Modifica la Community",
	"upload image": "Carica un'immagine",
	"Select a category": "Seleziona una categoria",
	"Type of Giki": "Tipo di Community",
	Public: "Pubblica",
	Private: "Privata",
	Cancel: "Annulla",
	"Save changes": "Salva i cambiamenti",
	Error: "Errore",
	"something went wrong": "Qualcosa è andato storto",
	"Leave this Giki": "Lascia la Community",
	Unmute: "Riattiva",
	"Mute for": "Disattiva",

	hours: "ore",
	week: "settimane",
	"Mute forever": "Muta",
	Docs: "Documenti",
	Link: "Link",
	You: "Tu",
	Reply: "Rispondi",
	Copy: "Copia",
	Pin: "Fissa il messaggio",
	Delete: "Elimina",
	Message: "Messaggi",
	"Start Giking": "Inizia",
	"Welcome to": "Benvenuto in",
	"Profile info": "Dettagli del profilo",
	"Privacy Policy": "Privacy Policy",
	Logout: "Logout",
	"Help Center": "Centro assistenza",
	"Change password": "Cambia la password",
	"Email (this email can be different from the one registered)":
		"Email (questa email può essere diversa da quella registrata)",
	"Professional Info": "Dettagli professione",
	"Reason to contact": "Hai bisogno di aiuto? Come possiamo aiutarti?",
	General: "Generale",
	Send: "Invia",
	"Contact information": "Informazioni di contatto",

	"Enrolled Successfully": "Iscritto con successo",
	"The conference created by": "La conference è stata creata da",
	"Live at": "Live il",
	"Join Meeting": "Accedi al Meeting",
	"Start Meeting": "Avvia il Meeting",
	Enrolled: "Iscritto",
	Enroll: "Iscriviti",

	"End call": "Concludi il Meeting",
	"Share Screen": "Condividi lo schermo",
	"full screen": "A schermo intero",
	"End meeting": "Concludi il meeting",
	"toggle video": "Attiva/disattiva video",

	"Join Now": "Unisciti ora",
	"Go to chat": "Vai alla chat",
	"ON AIR": "In diretta",

	request: "richiesta",

	"All New": "Le nuove community di", // this on is inside the category in the main page where it says: All New Category Name
	All: "Tutte le community di",
	GIKI_PLACEHOLDER: "Community",
	Suggest: "Consiglia una Community",
	"By requesting a new Giki, you agree to":
		"Richiedendo una nuova Community accetti:",
	"Giki guidelines": "Linee guida della Community",
	"Select a Giki": "Seleziona una categoria",
	"Describe your Giki": "Descrivi la tua Community",
	at: "il",

	next: "Prossimo",
	prev: "Precedente",
	new: "Nuovo",

	Attendees: "Partecipanti",
	"deleted successfully": "Cancellazione completata",
	"LIVE STREAMING": "LIVE STREAMING",
	"Privacy Policy Xgate": "Privacy Policy Xgate",

	"Invite member from outside the platform": "Invita un nuovo utente esterno",
	Submit: "Seleziona",
	"Invite new members": "Invita un nuovo utente",
	follows: "Community seguite da",

	"Can't create a conference in past time or date":
		"Impossibile creare una conferenza in una data o ora passata",
	"Enter a title for the conference": "Inserisci un titolo per la conferenza",
	"Enter a description": "Inserisci una descrizione",
	"Start Date": "Data di inizio",
	"Select a the date of conference": "Seleziona la data della conferenza",
	"Create conference": "Crea conferenza",
	"My Profile": "Il mio profilo",
	"Personal Info": "Informazioni personali",
	"this community doesn't exist": "Questa Community non esiste",
	"Users Invited Successfully": "Utenti invitati con successo",
	"End Date": "Ora di fine",
	"Start Date Can't be after End Date":
		"Non è possibile pianificare una nuova conferenza durante lo svolgimento di un'altra",
		"unavailable_slot": "Non è possibile pianificare una nuova conferenza durante lo svolgimento di un'altra",
	// "Select a the end date of conference": "Select a the end date of conference",
};
