import React from "react";

const Friends = ({ className }: { className?: string }) => {
  return (
    <svg
      width='26'
      height='27'
      viewBox='0 0 26 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.81817 7.73058C3.61326 7.73058 2.63635 6.75367 2.63635 5.54876C2.63635 4.34385 3.61326 3.36694 4.81817 3.36694C6.02308 3.36694 6.99999 4.34385 6.99999 5.54876C6.99999 6.75367 6.02308 7.73058 4.81817 7.73058Z'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M6.45455 24.0941H5.00825C3.97384 24.0941 3.11011 23.3054 3.01646 22.2752L2.71065 18.9113C2.66552 18.4149 2.3308 17.9923 1.8579 17.8346V17.8346C1.34557 17.6639 1 17.1844 1 16.6444V12.6396C1 11.4347 1.97691 10.4578 3.18182 10.4578H6.45455C7.24764 10.4578 7.942 10.8816 8.32382 11.5143'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M21.1818 7.73058C22.3867 7.73058 23.3636 6.75367 23.3636 5.54876C23.3636 4.34385 22.3867 3.36694 21.1818 3.36694C19.9769 3.36694 19 4.34385 19 5.54876C19 6.75367 19.9769 7.73058 21.1818 7.73058Z'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M19.5454 24.0941H20.9917C22.0261 24.0941 22.8899 23.3054 22.9835 22.2752L23.2893 18.9113C23.3344 18.4149 23.6692 17.9923 24.1421 17.8346V17.8346C24.6544 17.6639 25 17.1844 25 16.6444V12.6396C25 11.4347 24.0231 10.4578 22.8181 10.4578H19.5454C18.7523 10.4578 18.058 10.8816 17.6761 11.5143'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M13 8.27592C11.1924 8.27592 9.72729 6.81083 9.72729 5.0032C9.72729 3.19556 11.1924 1.73047 13 1.73047C14.8077 1.73047 16.2727 3.19556 16.2727 5.0032C16.2727 6.81083 14.8077 8.27592 13 8.27592Z'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M15.8801 23.8965C15.7937 24.9331 14.9271 25.7304 13.887 25.7304H12.1129C11.0728 25.7304 10.2062 24.9331 10.1199 23.8965L9.81657 20.257C9.76315 19.616 9.30763 19.0801 8.68358 18.9241V18.9241C8.01467 18.7569 7.54541 18.1558 7.54541 17.4663V13.185C7.54541 11.9801 8.52232 11.0032 9.72723 11.0032H16.2727C17.4776 11.0032 18.4545 11.9801 18.4545 13.185V17.4663C18.4545 18.1558 17.9852 18.7569 17.3163 18.9241V18.9241C16.6923 19.0801 16.2368 19.616 16.1833 20.257L15.8801 23.8965Z'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  );
};

export default Friends;
