import logo from "../../assets/svgs/giki-logo.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface AuthenticationLayoutProps {
	children: React.ReactNode;
}

const AuthenticationLayout: React.FunctionComponent<
	AuthenticationLayoutProps
> = ({ children }) => {
	const { pathname } = useLocation();
	const data = localStorage.getItem("main_logo");
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-7 bg-blue-primary w-full min-h-dvh">
			<div
				className={`col-span-7    
          ${pathname === "/register" ? "lg:col-span-2" : "lg:col-span-3"}
          grid grid-cols-1`}
			>
				<div className="flex items-center justify-center py-4 lg:py-0">
					<img
						className="lg:w-[190px] lg:h-[170px] w-[148px] h-[148px]"
						draggable={false}
						src={data ? `${process.env.REACT_APP_MEDIA_URL}${data}` : logo}
						alt="Giki Logo"
					/>
				</div>
				<div />
			</div>
			<div
				className={`col-span-7
         ${pathname === "/register" ? "lg:col-span-5" : "lg:col-span-4"}
         bg-gray-50 rounded-t-[20px] lg:rounded-l-[20px] lg:rounded-tr-none flex justify-center items-start py-9 lg:py-0`}
			>
				<div
					style={{ height: "-webkit-fill-available" }}
					className={`grid grid-cols-2 gap-12 ${
						pathname === "/register" ? "mx-[5%]" : "mx-[5%]"
					}  mt-0 lg:mt-36 w-full`}
				>
					{children}
					<ul className="text-blue-primary col-span-2 gap-x-10 font-light text-xs leading-[18px] select-none hidden lg:flex place-self-end justify-self-center pb-4">
						{/* <NavLink to="/about" className="cursor-pointer">
							{({ isActive }) => (
								<span className={isActive ? "font-semibold" : ""}>About</span>
							)}
						</NavLink> */}
						<NavLink
							to="https://terapiainfiltrativapro.it/xgate-policy"
							target={"_blank"}
							className="cursor-pointer"
						>
							{({ isActive }) => (
								<span className={isActive ? "font-semibold" : ""}>
									{t("Privacy Policy Xgate")}
								</span>
							)}
						</NavLink>
						<NavLink
							to="https://terapiainfiltrativapro.it/privacy-policy"
							className="cursor-pointer"
						>
							{({ isActive }) => (
								<span className={isActive ? "font-semibold" : ""}>
									{t("Privacy")}
								</span>
							)}
						</NavLink>
						<NavLink
							to="https://terapiainfiltrativapro.it/termini-uso"
							className="cursor-pointer"
						>
							{({ isActive }) => (
								<span className={isActive ? "font-semibold" : ""}>
									{t("Terms of use")}
								</span>
							)}
						</NavLink>
						{/* <NavLink to="/faq" className="cursor-pointer">
							{({ isActive }) => (
								<span className={isActive ? "font-semibold" : ""}>FAQ</span>
							)}
						</NavLink> */}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default AuthenticationLayout;
