const MsgGroup = ({ className }: { className?: string }) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <mask id='path-1-inside-1_873_511' fill='white'>
        <path d='M10.4531 21.3798C11.8326 22.0859 13.4891 22.4978 15.2728 22.4978C15.6857 22.4978 16.0915 22.4743 16.4887 22.4306C16.9163 22.3836 17.3516 22.4451 17.7345 22.641L18.9073 23.241C20.2381 23.9219 21.8182 22.9554 21.8182 21.4605V21.1299C21.8182 20.5518 22.0805 20.0125 22.4665 19.5822C23.4316 18.5062 24 17.2051 24 15.8001C24 15.2207 23.904 14.6604 23.724 14.1262' />
      </mask>
      <path
        d='M11.1366 20.0445L9.80129 19.3611L8.43443 22.0316L9.7697 22.7151L11.1366 20.0445ZM25.1455 13.6472L24.6665 12.2257L21.8236 13.1838L22.3026 14.6052L25.1455 13.6472ZM18.9073 23.241L18.2241 24.5764L18.9073 23.241ZM16.4887 22.4306L16.3247 20.9396L16.4887 22.4306ZM17.7345 22.641L17.0513 23.9764L17.7345 22.641ZM9.7697 22.7151C11.369 23.5336 13.2609 23.9978 15.2728 23.9978V20.9978C13.7173 20.9978 12.2962 20.6381 11.1366 20.0445L9.7697 22.7151ZM15.2728 23.9978C15.7426 23.9978 16.2031 23.971 16.6527 23.9216L16.3247 20.9396C15.9798 20.9775 15.6288 20.9978 15.2728 20.9978V23.9978ZM17.0513 23.9764L18.2241 24.5764L19.5905 21.9056L18.4177 21.3056L17.0513 23.9764ZM23.3182 21.4605V21.1299H20.3182V21.4605H23.3182ZM23.5832 20.5837C24.7558 19.2763 25.5 17.6296 25.5 15.8001H22.5C22.5 16.7805 22.1074 17.7361 21.3499 18.5807L23.5832 20.5837ZM25.5 15.8001C25.5 15.0529 25.3759 14.3309 25.1455 13.6472L22.3026 14.6052C22.4322 14.9898 22.5 15.3886 22.5 15.8001H25.5ZM23.3182 21.1299C23.3182 21.0138 23.3745 20.8164 23.5832 20.5837L21.3499 18.5807C20.7866 19.2087 20.3182 20.0898 20.3182 21.1299H23.3182ZM18.2241 24.5764C20.553 25.7679 23.3182 24.0765 23.3182 21.4605H20.3182C20.3182 21.8342 19.9232 22.0758 19.5905 21.9056L18.2241 24.5764ZM16.6527 23.9216C16.8346 23.9016 16.9677 23.9336 17.0513 23.9764L18.4177 21.3056C17.7355 20.9565 16.998 20.8655 16.3247 20.9396L16.6527 23.9216Z'
        fill='white'
        mask='url(#path-1-inside-1_873_511)'
      />
      <path
        d='M6.42136 17.8116L6.763 18.4793L6.42136 17.8116L4.75121 18.6662C3.91946 19.0918 2.93182 18.4877 2.93182 17.5534V15.7795C2.93182 15.0824 2.66099 14.4496 2.2754 13.95C1.30339 12.6905 0.75 11.2234 0.75 9.66058C0.75 5.2759 5.15086 1.48035 10.9091 1.48035C16.6673 1.48035 21.0682 5.2759 21.0682 9.66058C21.0682 14.0453 16.6673 17.8408 10.9091 17.8408C10.0289 17.8408 9.17695 17.7467 8.3625 17.5747C7.72923 17.4409 7.03864 17.4957 6.42136 17.8116Z'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  );
};

export default MsgGroup;
