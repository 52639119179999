/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
import {
	Avatar,
	Box,
	Button,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Textarea,
	useToast,
	WrapItem,
} from "@chakra-ui/react";

import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";
import camera from "../../assets/svgs/discover/popup/camera.svg";

// import { Link } from "react-router-dom";
import { useApiRequests } from "../../configs/API/ApiRequests";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Category } from "../../types";
import { useTranslation } from "react-i18next";

interface SuggestGikiButtonProps {
	children: React.ReactNode;
	onClose: () => void;
	isOpen: boolean;
}

const SuggestGikiButton: React.FunctionComponent<SuggestGikiButtonProps> = ({
	children,
	isOpen,
	onClose,
}) => {
	const { t } = useTranslation();
	const { getCategories, suggestNewCommunity } = useApiRequests();
	const { data, isLoading } = useQuery({
		queryKey: ["categories"],
		queryFn: getCategories,
	});
	const suggestCommunity = useMutation({ mutationFn: suggestNewCommunity });
	const { register, handleSubmit, reset } = useForm();
	const fileRef = useRef<HTMLInputElement>(null);
	const [selectedFile, setSelectedFile] = useState<Blob | null>();
	const toast = useToast();
	const handleSelectFile = (e: any) => {
		const file = e.target.files[0];
		setSelectedFile(file);
	};

	const onSubmit = (data: any) => {
		const formData = new FormData();
		formData.append("name", data.name);
		formData.append("purpose", data.purpose);
		formData.append("category_id", data.category);
		formData.append("type", data.type);
		formData.append("image", selectedFile as Blob);

		suggestCommunity.mutate(formData, {
			onSuccess: () => {
				reset();
				setSelectedFile(null);
				onClose();
			},
			onError: (error: any) => {
				const errorsArr = [];
				if (typeof error.response.data.error !== "string") {
					const errors = error.response.data.error;
					for (const key in errors) {
						// Access the array using the key
						const array = errors[key];
						const firstValue = array[0];
						errorsArr.push(firstValue);
					}
				} else {
					errorsArr.push(error.response.data.error);
				}
				toast({
					title: "Error",
					description: errorsArr.join(", "),
					status: "error",
					duration: 4000,
					isClosable: true,
				});
			},
		});
	};

	return (
		<>
			{children}

			<Modal isCentered isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent className="sm:min-w-[500px]">
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="flex flex-col gap-4 px-4"
						>
							<WrapItem
								position="relative"
								mx="auto"
								alignItems="center"
								w="fit-content"
								flexDirection="column"
							>
								<input
									{...register("image")}
									onChange={handleSelectFile}
									ref={fileRef}
									type="file"
									hidden
								/>

								{selectedFile ? (
									<>
										<Avatar
											opacity={0.6}
											w="100px"
											h="100px"
											src={URL.createObjectURL(selectedFile)}
											rounded="8px"
											borderColor="gray-20"
										/>
										{selectedFile && (
											<div className="absolute -right-2 bottom-0">
												<Box
													rounded="full"
													className="cursor-pointer flex justify-center p-1 items-center w-6 h-6"
													bg="gray.300"
													onClick={() => fileRef?.current?.click()}
												>
													<AiFillDelete
														onClick={() => setSelectedFile(null)}
														className="text-xl text-red-600"
													/>
												</Box>
											</div>
										)}
									</>
								) : (
									<>
										<div className="w-[100px] h-[100px] rounded-lg border border-gray-20 flex justify-center items-center">
											<div className="bg-light-gray w-[52px] h-[52px] rounded-lg "></div>
										</div>
										<label
											className="w-full absolute right-1/2 bottom-1/2 translate-x-1/2 translate-y-1/2 "
											htmlFor="contained-button-file"
										>
											{!selectedFile && (
												<Box
													rounded="8px"
													className="cursor-pointer flex flex-col justify-center items-center"
													onClick={() => fileRef?.current?.click()}
												>
													<img src={camera} />
													<p className="text-[10px] text-gray-700">
														{t("upload image")}
													</p>
												</Box>
											)}
										</label>
									</>
								)}
							</WrapItem>

							<Input
								{...register("name", { required: true })}
								variant="user-outline"
								className="text-xs placeholder:text-xs"
								placeholder={t("GIKI_PLACEHOLDER") + " Name"}
								required
							/>

							<Textarea
								{...register("purpose", { required: true })}
								variant="user-outline"
								placeholder={`${t("Describe your Giki")}`}
								className="border border-gray-20 text-xs placeholder:text-xs"
								required
							/>

							<Select
								disabled={isLoading}
								{...register("category", { required: true })}
								border="solid 2px #bbbbbb59"
								borderColor={false ? "red.400" : "#bbbbbb59"}
								variant="register-outline"
								className=" placeholder:text-grap-20 text-xs placeholder:text-xs"
								required
								fontSize="12px"
							>
								<option value="">{t("Select a Giki")}</option>
								{data?.data.length > 0 &&
									data?.data?.map((option: Category) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
							</Select>

							<Select
								{...register("type", { required: true })}
								required
								border="solid 2px #bbbbbb59"
								borderColor={false ? "red.400" : "#bbbbbb59"}
								variant="register-outline"
								className=" placeholder:text-grap-20"
								fontSize="12px"
							>
								<option value="">{t("Type of Giki")}</option>
								<option value="0">{t("Public")}</option>
								<option value="1">{t("Private")}</option>
							</Select>

							<div className="flex gap-[10px] flex-col sm:flex-row">
								<Button
									fontSize={{ base: "14px", lg: "14px" }}
									onClick={onClose}
									bg="blue-primary-50"
									_hover={{ bg: "blue-primary-80" }}
									variant="primary"
									disabled={suggestCommunity.isPending}
								>
									{t("Cancel")}
								</Button>
								<Button
									isLoading={suggestCommunity.isPending}
									disabled={suggestCommunity.isPending}
									fontSize={{ base: "14px", lg: "14px" }}
									type="submit"
									bg="orange-primary"
									_hover={{ bg: "blue-primary-80" }}
									variant="primary"
								>
									{t("Suggest")}
								</Button>
							</div>

							{/* <p className="text-center text-[10px]">
								{t("By requesting a new Giki, you agree to")}{" "}
								<Link
									style={{ textDecoration: "none" }}
									className="text-orange-primary"
									to="/faq"
								>
									{t("Giki guidelines")}
								</Link>
							</p> */}
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default SuggestGikiButton;
