import { NavLink } from "react-router-dom";
import logo from "../../assets/svgs/sidebar/logo.svg";
import { Avatar, Button, IconButton, useDisclosure } from "@chakra-ui/react";
import SuggestGikiButton from "../../components/buttons/SuggestGikiButton";
import Home from "../../components/icons/Home";
import MsgGroup from "../../components/icons/MsgGroup";
import Friends from "../../components/icons/Friends";
import SettingsIcon from "../../components/icons/SettingsIcon";
import { superSearchAtom } from "../../store/atoms";
import { useRecoilState } from "recoil";
import search from "../../assets/svgs/sidebar/mobile-search.svg";
import { FaSearch } from "react-icons/fa";

interface SidebarProps { }

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [superSearch, setSuperSearch] = useRecoilState(superSearchAtom);

	return (
		<>
			<nav className="w-[90px] bg-blue-primary  flex-col gap-5 h-svh hidden md:flex sticky top-0 z-50">
				<NavLink to="" className="pt-5 px-[10px] self-center">
					<Avatar
						src={
							localStorage.getItem("logo")
								? `${process.env.REACT_APP_MEDIA_URL}${localStorage.getItem(
									"logo"
								)}`
								: logo
						}
						name={"Giki"}
						rounded={4}
						className="w-[60px] p-[10px]"
					/>
				</NavLink>
				<div className="flex flex-col gap-5 justify-between flex-1">
					<ul
						className={`flex flex-col w-full items-center lg:flex-1 gap-8 sm:gap-10 style-scrollbar`}
					>
						<NavLink
							className="w-full flex justify-center relative scroll-mt-10"
							to={"/"}
						>
							{({ isActive }) => (
								<p
									className={`!stroke-white w-[54px] h-[54px] rounded-[4px] px-[15px] py-4 transition-colors duration-300 hover:bg-[#FFFFFF66] ${isActive && "bg-[#FFFFFF66]"
										}`}
								>
									<Home />
								</p>
							)}
						</NavLink>
						<NavLink className="w-full flex justify-center relative" to="/chat">
							{({ isActive }) => (
								<p
									className={`!stroke-white w-[54px] h-[54px] rounded-[4px] px-[15px] py-4 transition-colors duration-300 hover:bg-[#FFFFFF66] ${isActive && "bg-[#FFFFFF66]"
										}`}
								>
									<MsgGroup />
								</p>
							)}
						</NavLink>
						<NavLink
							className="w-full flex justify-center relative"
							to="/members"
						>
							{({ isActive }) => (
								<p
									className={`stroke-white w-[54px] h-[54px] rounded-[4px] px-[15px] py-4 transition-colors duration-300 hover:bg-[#FFFFFF66] ${isActive && "bg-[#FFFFFF66]"
										}`}
								>
									<Friends />
								</p>
							)}
						</NavLink>
					</ul>
					<div className="flex justify-center flex-col items-center gap-6 p-5">
						<div className="w-full flex justify-center relative">
							<SuggestGikiButton onClose={onClose} isOpen={isOpen}>
								<Button
									onClick={onOpen}
									minW="25px"
									w="25px"
									minH="25px"
									height="25px"
									className="!rounded"
									p="0"
									bgColor="orange-primary"
									_hover={{ bgColor: "orange-primary-80" }}
								>
									<svg
										width="17"
										height="16"
										viewBox="0 0 17 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.25033 2.66699V13.3337M13.5837 8.00033L2.91699 8.00032"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Button>
							</SuggestGikiButton>
						</div>
						<button
							aria-label="search"
							className={`w-[54px] h-[54px] rounded-[4px] px-[15px] py-4 flex items-center justify-center text-white transition-colors duration-300 hover:bg-[#FFFFFF66] ${superSearch && "bg-[#FFFFFF66]"
								}`}
							onClick={() => {
								setSuperSearch((prev: any) => !prev);
							}}
						>
							<FaSearch />
						</button>
						<NavLink
							className="w-full flex justify-center relative"
							to="/profile"
						>
							{({ isActive }) => (
								<p
									className={`w-[54px] h-[54px] rounded-[4px] px-[15px] py-4 transition-colors duration-300 hover:bg-[#FFFFFF66] ${isActive && "bg-[#FFFFFF66]"
										}`}
								>
									<SettingsIcon />
								</p>
							)}
						</NavLink>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Sidebar;
